<template lang="pug">
v-card(v-if="Object.keys(sailorDocument).length")
  v-card-title.d-flex.flex-wrap.justify-space-between
    div {{ $t(`${Object.keys(sailorDocument.behavior)[0]}-${type}`, { number: sailorDocument.number_document }).toUpperCase() }}
      span(v-if="checkAccess('backOffice')") (ID: {{ sailorDocument.id }})
    ControlButtons(:controlButtons="controlButtons").justify-end
  v-divider
  v-card-text(v-if="!isLoading")
    Info(
      v-if="sailorDocument.behavior.viewInfoBlock && !$route.query.viewEditBlock"
      :sailorPassport="sailorDocument")

    Form(
      v-else-if="sailorDocument.behavior.viewEditBlock || $route.query.viewEditBlock"
      :sailorPassport="sailorDocument"
      actionType="editing")

    EditStatus(
      v-else-if="sailorDocument.behavior.viewEditStatusBlock"
      :sailorDocument="sailorDocument")

    VerificationSteps(
      v-else-if="sailorDocument.behavior.viewVerificationStepsBlock"
      :sailorDocument="sailorDocument"
      getFunctionName="getSailorPassport")

    ViewPhotoList(
      v-else-if="sailorDocument.behavior?.viewFilesBlock"
      :files="sailorDocument.files"
      :sailorDocument="sailorDocument"
      :documentType="type"
      :deleteFile="removeFileSailorPassport"
      :editFile="updateFileSailorPassport"
      removeOnce
      isShowEdit
    )
  v-progress-linear(v-else indeterminate color="primary")

</template>

<script >
import { viewDetailedComponent, back, deleteConfirmation, copyToClipboard } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { mapState, mapGetters, mapActions } from 'vuex'
import { SUCCESS_CODE } from '@/configs/constants'

export default {
  name: 'SailorPassportDocument',
  components: {
    Info: () => import('@/views/Sailor/SailorPassport/Info.vue'),
    EditStatus: () => import('@/views/Sailor/SailorPassport/EditStatus.vue'),
    ViewPhotoList: () => import('@/components/atoms/ViewFile/ViewFile.vue'),
    VerificationSteps: () => import('@/components/atoms/VerificationSteps/VerificationSteps.vue'),
    ControlButtons: () => import('@/components/atoms/ControlButtons.vue'),
    Form: () => import('./Form.vue')
  },
  data () {
    return {
      type: 'sailorPassport',
      comments: [],
      viewDetailedComponent,
      deleteConfirmation,
      checkAccess,
      back,
      controlButtons: [
        {
          id: 1,
          name: 'mdi-content-copy',
          action: () => copyToClipboard(location.href),
          color: '#42627e',
          tooltip: 'documentIconControl.copyLink',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 2,
          name: 'mdi-information-outline',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewInfoBlock'),
          color: '#42627e',
          tooltip: 'documentIconControl.info',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 3,
          name: 'mdi-pen',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewEditBlock'),
          checkAccess: () => checkAccess('sailorPassport', 'edit', this.sailorDocument),
          color: '#42627e',
          tooltip: 'documentIconControl.edit',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 4,
          name: 'mdi-sync',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewEditStatusBlock'),
          checkAccess: () => checkAccess('sailorPassport', 'editStatus', this.sailorDocument),
          color: '#42627e',
          tooltip: 'documentIconControl.editStatus',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 5,
          name: 'mdi-folder-edit-outline',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewVerificationStepsBlock'),
          checkAccess: () => this.sailorDocument.status_document === 34 && checkAccess('sailorPassport', 'verificationSteps', this.sailorDocument),
          color: '#dd7137ab',
          tooltip: 'documentIconControl.verificationSteps',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 6,
          name: 'mdi-image-outline',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewFilesBlock'),
          checkAccess: () => checkAccess('sailorPassport', 'view_file'),
          tooltip: 'documentIconControl.files',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 7,
          name: 'mdi-trash-can-outline',
          action: () => this.deleteDocument(),
          checkAccess: () => checkAccess('sailorPassport', 'delete', this.sailorDocument),
          color: '#F73E07',
          tooltip: 'documentIconControl.delete',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 8,
          name: 'mdi-close',
          action: () => this.$router.push({ name: 'passports-sailors', params: { id: this.$route.params.id } }),
          color: '#42627e',
          tooltip: 'documentIconControl.close',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['sailorDocumentByID']),
    ...mapState({
      id: state => state.sailor.sailorId,
      isLoading: state => state.sailor.isLoading,
      labelName: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      sailorDocument: state => state.sailor.sailorPassportById
    })
  },
  mounted () {
    this.getData()
  },
  methods: {
    ...mapActions(['getSailorPassportById', 'deleteSailorPassportById', 'removeFileSailorPassport',
      'updateFileSailorPassport', 'removeFileSailorPassport']),
    async getData () {
      this.getSailorPassportById({ ...this.$route.params })
    },
    deleteDocument () {
      deleteConfirmation(this).then(async (confirmation) => {
        if (confirmation) {
          const response = await this.deleteSailorPassportById({ ...this.$route.params })
          if (SUCCESS_CODE.includes(response.code)) {
            this.$notification.success('deletedDocument')
            back('passports-sailors')
          } else {
            this.$notification.error('cantDeleteDoc')
          }
        }
      })
    }
  }
}
</script>
